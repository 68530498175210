const faqModel = {
    faqs: [
        {
            id: "faq1",
            question: "This is a test question",
            answer: "This is a test answer"
        },
        {
            id: "faq2",
            question: "This is a test question 2",
            answer: "This is a test answer 2"
        },
        {
            id: "faq3",
            question: "This is a test question 3",
            answer: "This is a test answer 3"
        }
    ]
}

export default faqModel;

// format: id (faqN), question, answer